




































































































































import { Component, Prop } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import qs from 'qs'
import ListVulnInfo from './components/listVulnInfo.vue'

@Component({
  name: 'LinkList',
  components: {
    LinkList,
    ListVulnInfo,
  },
})
export default class LinkList extends VueBase {
  @Prop() treeList: any

  private open = false

  private toVuln(vItem: any) {
    this.$router.push({
      path: '/project/leakDetail/' + vItem.project_id + '/' + vItem.id,
    })
  }

  private async getVulnInfo(vItem: any) {
    if (vItem.vulnInfo) {
      vItem.open = !vItem.open
      this.$forceUpdate()
      return
    }
    this.loadingStart()
    const { data, status, msg } = await this.services.vuln.getVulnDetail(
      vItem.id
    )
    if (status !== 201) {
      this.$message.error(msg)
      return
    }
    vItem.vulnInfo = data
    vItem.open = !vItem.open
    this.$forceUpdate()
    this.loadingDone()
  }

  private async getVulnDetail(tree: any) {
    if (tree.vulnList && tree.vulnList.length) {
      this.$set(tree, 'open', !tree.open)
      return
    }
    this.loadingStart()
    const res = await this.services.vuln.vulnList({
      agent_id: tree.agent_id,
    })
    this.loadingDone()
    if (res.status !== 201) {
      this.$message.error(res.message)
      return
    }
    tree.vulnList = res.data
    this.$set(tree, 'open', !tree.open)
  }

  private hasNode(tree: any) {
    return (
      tree.vul_level_high ||
      tree.vul_level_info ||
      tree.vul_level_low ||
      tree.vul_level_medium ||
      tree.vul_level_note
    )
  }

  private nodeColor(tree: any) {
    if (tree.vul_level_high) return '#E56363'
    if (tree.vul_level_medium) return '#F49E0B'
    if (tree.vul_level_low) return '#2F90EA'
    if (tree.vul_level_note) return '#ABB2C0'
    if (tree.vul_level_info) return '#DBDBDB'
  }

  private levelColor(level: any) {
    switch (level) {
      case 1:
        return '#E56363'
      case 2:
        return '#F49E0B'
      case 3:
        return '#2F90EA'
      case 4:
        return '#ABB2C0'
      case 5:
        return '#DBDBDB'
    }
  }

  private bgColor(level_type: number) {
    switch (level_type) {
      case 1:
        return 'rgba(229, 99, 99, 0.1)'
      case 2:
        return 'rgba(244, 158, 11, 0.1)'
      case 3:
        return 'rgba(47, 144, 234, 0.1)'
      case 4:
        return 'rgba(172, 180, 196, 0.1)'
      case 5:
        return 'rgba(219, 219, 219, 0.1)'
    }
  }
}
